import images from "./images";

const roadtrips = [
  {
    id: 1,
    img: images.synchronicity,
    name: "Synchronicity",
    link: "https://synchronicity.antaragni.in/",
  },
  {
    id: 2,
    img: images.junoon,
    name: "Junoon",
    link: "https://junoon.antaragni.in/",
  },
  {
    id: 3,
    img: images.bug,
    name: "bug",
    link: "https://bug.antaragni.in/",
  },
  {
    id: 4,
    img: images.djwar,
    name: "djwar",
    link: "https://djwar.antaragni.in/",
  },
  {
    id: 5,
    img: images.comickaun,
    name: "comickaun",
    link: "https://comickaun.antaragni.in/",
  }  
];

export default roadtrips;
