import React from "react";
import "./Landing.css";
import roadtrips from "../data";

const Landing = () => {
  return (
    <>
      <div className="app__landing">
        <div className="container-fluid">
          <div className="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 landing__row">
            {roadtrips.map((roadtrip) => (
              <div className="col mb-5 ps-3 pe-3" key={roadtrip.id}>
                <a target="_blank" rel="noreferrer" href={roadtrip.link} alt={roadtrip.name}>
                  <img
                    className="landing__img"
                    src={roadtrip.img}
                    alt={roadtrip.name}
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
