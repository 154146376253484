import synchronicity from "./assets/synchronicity.png";
import junoon from "./assets/junoon.png";
import bug from "./assets/bug.png";
import djwar from "./assets/djwar.png";
import comickaun from "./assets/comickaun.png";
import bg from "./assets/bg.png";

const images = {
    synchronicity,
    junoon,
    bug,
    djwar,
    comickaun,
    bg,
};

export default images;